import moment from "moment";
import { useLocation } from "react-router-dom";
import { BoardList, CURRENCY } from "./variables";

export const currencyFormatter = (amt: any) =>
  Intl.NumberFormat().format(amt || 0);

export const mapOutBoard = (list: any) =>
  list.map((item: any) => BoardList.find((board) => board.code === item));

export const useRouteQuery = () => new URLSearchParams(useLocation().search);

export const dateFormatter = (date: any) => {
  const _date = date
    ? moment(new Date(date)).format(`DD-MM-YYYY`)
    : "invalid date";
  return _date.toLowerCase() === "invalid date" ? "-" : _date;
};

export const checkCurrency = (value: any) => {
  if (value === "naira") return CURRENCY.NAIRA;
  else if (value === "dollar") return CURRENCY.DOLLAR;
  else return CURRENCY.KSH;
};

export const lastUpdatedFormatter = (date: any) => {
  const _date = moment(new Date(date)).format(`DD-MM-YYYY, hh:mm A`);
  return _date.toLowerCase() === "invalid date" ? "-------" : _date;
};

export const clientOverlayFormatter = (date: any) => {
  const _date = moment(new Date(date)).format(`Do MMMM, YYYY`);
  return _date.toLowerCase() === "invalid date" ? "-------" : _date;
};

export const dateRangeFormatter = (date: any) => {
  const _date = moment(new Date(date)).format(`MMM Do YY`);
  return _date.toLowerCase() === "invalid date" ? "-------" : _date;
};

export const checkBoardName = (security: any) => {
  if (security.toLowerCase().startsWith("s")) {
    return "Spot";
  } else if (security.toLowerCase().startsWith("d")) {
    return "Dawa";
  } else if (security.toLowerCase().startsWith("o")) {
    return "OTC";
  }
};

export const addBoardName = (arr: any, key: any) => {
  return arr?.map((item: any) => {
    return { ...item, board_name: checkBoardName(item[key]) };
  });
};

export const handleDateRange = (range: any) =>
  `date_to=${range.date_to}&date_from=${range.date_from}`;

export const handleBoardNameUrlFilter = (
  filters: any,
  url: any,
  extraQuery?: any
) => {
  const filter = filters.find((filter: any) => filter.value === "board_name");
  if (filter.showAll) {
    return extraQuery ? `${url}${extraQuery}` : url;
  } else {
    return extraQuery
      ? `${url}${extraQuery}&board_names=${filter.filters.map(
          (item: any) => item.value
        )}`
      : `${url}?board_names=${filter.filters.map((item: any) => item.value)}`;
  }
};

export function falsify(value?: boolean | string) {
  if (!value) return false;
  if (typeof value === "boolean") return value;
  return value === "False" ? false : true;
}
